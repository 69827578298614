
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































































.equipment-form-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.equipment-form {
  position: relative;
  width: 100%;
  background-color: $c-main-beige;
}

.equipment-form__button {
  margin-bottom: $spacing * 2;
  transition: opacity 0.2s ease-out;

  .is-open & {
    opacity: 0;
    pointer-events: none;
  }
}

.equipment-form__fieldset {
  position: relative;
  align-items: flex-start;
}

.equipment-form__title {
  @extend %text-center;

  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: $spacing * 3;

  @include mq(l) {
    margin-bottom: $spacing * 5;
  }

  @include mq(xl) {
    margin-bottom: $spacing * 8;
  }
}

.equipment-form__title__close {
  @include center-y;

  right: -1rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  transform: translate(100%, -50%);

  &::before {
    @include center-xy;

    content: '';
    display: block;
    width: 1.5rem;
    height: 2px;
    background-color: $c-black;
  }

  &::after {
    @include get-all-space;

    content: '';
    display: block;
    border: 1px solid $c-gray-medium;
    border-radius: 4rem;
  }
}

.equipment-form__container {
  ::v-deep {
    .form-confirmation {
      padding-right: col(1);

      @include mq(m) {
        padding-right: col(2);
      }
    }
  }

  .equipment-form__input__file {
    border-bottom: 1px solid $c-gray-medium;
    transition: border-color 0.2s;

    ::v-deep {
      .form-label {
        cursor: pointer;
        z-index: 9;
        width: 100%;

        &::after {
          @include center-y;

          content: '';
          z-index: 9;
          display: block;
          width: calc(100% + 15rem);
          height: 5rem;
        }
      }

      .form-input {
        opacity: 0;

        &::-webkit-file-upload-button {
          visibility: hidden;
        }
      }
    }
  }
}
